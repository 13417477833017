/* You can add global styles to this file, and also import other style files */
html, body {
    height: 100%;
}

.noscroll { 
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
}
::-webkit-scrollbar-thumb {
    background: green; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #00b300; 
}



body {
    font-size: 16px;
    font-family: Arial,Helvetica,sans-serif;
    letter-spacing: 0.04em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: #444;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
img {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}